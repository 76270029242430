const aws = {
  region: "eu-west-1",
  identityPoolId: "eu-west-1:82a91e4c-beeb-487d-84f7-7e376497ab5c",
  userPoolId: "eu-west-1_ztxQea13g",
  userPoolClientId: "23fl3e3eunud61vsautvb2pkoo",
  userManagementAppsyncEndpoint: "https://yi7bbywcyfb6vhe3brpwczmbvy.appsync-api.eu-west-1.amazonaws.com/graphql",
  stockManagementAppsyncEndpoint: "https://ujepvsuxf5djnbnafuhkqgwqym.appsync-api.eu-west-1.amazonaws.com/graphql",
  productionManagementAppsyncEndpoint: "https://d5xcg45fo5cjlb7hxwder3ejee.appsync-api.eu-west-1.amazonaws.com/graphql"
}
export default aws
